import {Injectable} from "@angular/core";
import anime from "animejs/lib/anime.es";

type AnimeInstance = any;

@Injectable({ providedIn: 'root' })
export class AnimeManagerService {
  private _animes: AnimeInstance[] = [];


  public startAnime(params: any): AnimeInstance {
    const animeInstance = anime(params);
    this._animes.push(animeInstance);
    return animeInstance;
  }

}
