import {HostListener, Injectable, OnDestroy} from "@angular/core";
import {BehaviorSubject, fromEvent, Observable, Subject} from "rxjs";
import {map, mergeMap, takeUntil, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MobileService implements OnDestroy {
  private _destroyRx = new Subject<boolean>();
  public isMobileRx = new BehaviorSubject<boolean>(false);
  constructor() {
    const checkScreenSize = () => /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
          navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 || window.innerWidth < 1024;

    this.isMobileRx.next(checkScreenSize());
    // Create observable from window resize event throttled so only fires every 500ms
    fromEvent(window, 'resize')
      .pipe(takeUntil(this._destroyRx))
      .subscribe(
        _ => {
          this.isMobileRx.next(checkScreenSize())
        }
      );
  }

  public getUserAgent() {
    let userAgent = navigator.userAgent
      || navigator.vendor
      || (window as any).opera
      || null;

    if (!userAgent)
      throw new Error('Failed to look for user agent information.');

    return userAgent;
  };

  ngOnDestroy(): void {
    this._destroyRx.next(true);
  }
}
