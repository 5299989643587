import {
  AfterContentInit,
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input, OnDestroy, OnInit,
  Renderer2,
} from '@angular/core';
import {LocomotiveScrollService} from "../service/locomotive-scroll.service";
import {AnimeManagerService} from "../service/anime-manager.service";
import {ScrollElement} from "../data/scroll";
import {MobileService} from "../service/mobile.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {MousewheelEvents} from "swiper/types";

@Directive({
  selector: '[appParallax]'
})
export class ParallaxDirective implements OnInit, AfterContentInit, OnDestroy {
  @Input() rootRef: HTMLElement;
  @Input() parallaxSpeed = 0.5;
  @Input() parallaxScale = 2.6;
  @Input() parallaxOffset = 0;
  private _parallaxActive: boolean | null = null;
  @Input() public set isParallaxActive(val: boolean) {
    this._parallaxActive = val;
    this.toggleParallax();
  }
  public get isParallaxActive(): boolean {
    return this._parallaxActive;
  }

  public destroy = new Subject<void>();
  public isMobile = true;

  constructor(
    public el: ElementRef,
    private renderer: Renderer2,
    public locomotiveScroll: LocomotiveScrollService,
    public animeManager: AnimeManagerService,
    public mobileService: MobileService
  ) {
    this.mobileService.isMobileRx
      .pipe(takeUntil(this.destroy))
      .subscribe(x => {
        this.isMobile = x;
    });
  }

  public isPhone() {
    return this.isMobile && window.innerWidth < 720;
  }
  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public getScrollOffset(scroll: { x: number, y: number }) {
    return this.isMobile ? scroll.y : scroll.x;
  }

  public getTriggerElementOffset(elementInView: { left: number, top: number, bottom: number }) {
    return this.isMobile ? (elementInView.bottom) : (elementInView.left);
  }

  public getElementOffsetBound(element: HTMLElement): number {
    return this.isMobile ? element.offsetHeight : element.offsetWidth;
  }

  public  getTransformString(delta): string {
    return this.isPhone() ? `translateY(${delta - this.parallaxOffset}%)` : `translateX(${-1 * delta - this.parallaxOffset}%)`;
  }
  ngAfterContentInit(): void {
  }

  ngOnInit(): void {
    if(this.isParallaxActive == null) {
      this.isParallaxActive = true;
    }

    this.toggleParallax();
  }

  public toggleParallax() {
    if(!this.isParallaxActive) {
      this.renderer.setStyle(this.el.nativeElement, 'transform', 'none');
      return;
    }
    this.renderer.setStyle(this.el.nativeElement, 'transform', 'scale(' + this.parallaxScale + ')');
    this.locomotiveScroll.on('scroll', evt => {
      const elementInView: any = Object.values(evt.currentElements).find((x: any) => x.el.id == this.rootRef.id) as any;
      if(!elementInView ) {
        return;
      }
      const delta = 1 - this.getScrollOffset(evt.scroll) / this.getTriggerElementOffset(elementInView);
      const transform = this.el .nativeElement.style.transform;

      const translateValue = this.getTransformString(delta * this.parallaxSpeed);
      const scales=transform.match(/scale\([\d.,\s]+\)/g, '');
      let scale =(scales && scales.length > 0)?  scales[0] : '';
      this.renderer.setStyle(this.el.nativeElement, 'transform', `${scale} ${translateValue}`);
    });
  }
}
// <!--&& element.classList.contains('zoomout-finished')-->

