import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PortfolioBaseComponent} from "../portfolio-base.component";
import {PortfolioImage, PortfolioType} from "../../../data/portoflio-model";

@Component({
  selector: 'app-portfolio-cvet32',
  templateUrl: './portfolio-cvet32.component.html',
  styleUrls: ['./portfolio-cvet32.component.scss'],
  providers: [{ provide: PortfolioBaseComponent, useExisting: PortfolioCvet32Component }]
})
export class PortfolioCvet32Component extends PortfolioBaseComponent implements OnInit {
  portfolioType = PortfolioType.cvet321

  public firstImages: PortfolioImage[] = [
    new PortfolioImage('./assets/new/cvet32/1/1.jpg'),
    new PortfolioImage('./assets/new/cvet32/1/2.jpg'),
    new PortfolioImage('./assets/new/cvet32/1/3.jpg'),
    new PortfolioImage('./assets/new/cvet32/1/4.jpg'),
    new PortfolioImage('./assets/new/cvet32/1/5.jpg'),
    new PortfolioImage('./assets/new/cvet32/1/6.jpg')
  ];

  public secondImages: PortfolioImage[] = [
    new PortfolioImage('./assets/new/cvet32/2/vertical/1.jpg'),
    new PortfolioImage('./assets/new/cvet32/2/vertical/2.jpg')
  ]

  public thirdImages: PortfolioImage[] = [
    new PortfolioImage('./assets/new/cvet32/2/horizontal/1.jpg'),
    new PortfolioImage('./assets/new/cvet32/2/horizontal/2.jpg')
  ]


  constructor(elRef: ElementRef) {
    super(elRef);
  }

  ngOnInit(): void {
  }
}
