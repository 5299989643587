import { AppModule } from './app/app.module';
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";

/**
 3) Убрать отображение прелоадера при переходах между страницами
 4) Скрыть левый блок - заголовок должен быть
 5) Меню растянуть на всю высоту и выделить более ярокй границей
 6) Вертикальный скроллинг для строницы контактов
 7) в заголовке подсветка меняет ширину - оч криво
 8)
 * */
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
