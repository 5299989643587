import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {PreloaderComponent} from "./components/preloader/preloader.component";
import {SwiperModule} from "swiper_angular";
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { AboutComponent } from './components/about-component/about.component';
import { RailtrackComponent } from './components/railtrack/railtrack.component';
import { ComeoutAnimationDirective } from './directives/comeout-animation.directive';
import { PowerSliderComponent } from './components/power-slider/power-slider.component';
import { BrandListComponent } from './components/brand-list/brand-list.component';
import { ImageViewComponent } from './components/image-view/image-view.component';
import { ImageViewDirective } from './directives/image-view.directive';
import { VideopreviewComponent } from './components/videopreview/videopreview.component';
import {HighlightWhenInviewDirective} from "./directives/highlight-when-inview.directive";
import {ParallaxDirective} from "./directives/parallax.directive";
import {ZoomoutDirective} from "./directives/zoomout.directive";
import { PortfolioMitteComponent } from './components/portfolio/portfolio-mitte/portfolio-mitte.component'
import { ListSwiperComponent } from "./components/list-swiper/list-swiper.component";
import { PortfolioCvet32Component } from "./components/portfolio/portfolio-cvet32/portfolio-cvet32.component";
import { PortfolioBaseComponent } from "./components/portfolio/portfolio-base.component";
import {PortfolioMitteMondComponent} from "./components/portfolio/portfolio-mitte-mond/portfolio-mitte-mond.component";
import {PortfolioArtecoComponent} from "./components/portfolio/portfolio-arteco/portfolio-arteco.component";
import {PortfolioCmtComponent} from "./components/portfolio/portfolio-cmt/portfolio-cmt.component";
import {ListSwiperNewComponent} from "./components/list-swiper-new/list-swiper-new.component";

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HeaderComponent,
    HomeComponent,
    ContactsComponent,
    AboutComponent,
    ListSwiperComponent,
    ListSwiperNewComponent,
    RailtrackComponent,
    ComeoutAnimationDirective,
    PowerSliderComponent,
    BrandListComponent,
    ImageViewComponent,
    ImageViewDirective,
    VideopreviewComponent,
    HighlightWhenInviewDirective,
    ParallaxDirective,
    ZoomoutDirective,
    PortfolioBaseComponent,
    PortfolioMitteComponent,
    PortfolioCvet32Component,
    PortfolioMitteMondComponent,
    PortfolioArtecoComponent,
    PortfolioCmtComponent
  ],
  imports: [
    BrowserModule,
    SwiperModule,
    BrowserAnimationsModule,
    MatIconModule,
    RouterModule.forRoot([
      { path: '**', component: AppComponent }
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
