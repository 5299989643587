import {Component, ElementRef, Input, OnInit, ViewChild, ViewChildren} from '@angular/core';
import anime from "animejs/lib/anime.es.js";
import {LocomotiveScrollService} from "../../service/locomotive-scroll.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-power-slider',
  templateUrl: './power-slider.component.html',
  styleUrls: ['./power-slider.component.scss']
})
export class PowerSliderComponent implements OnInit {
  public images = [
    '/assets/powerswiper/slider_2_big.jpg',
    '/assets/powerswiper/slider_1_big.jpg',
    '/assets/powerswiper/slider_3_big.jpg',
    '/assets/powerswiper/slider_4_big.jpg'
  ];
  public smallImages = [
    '/assets/powerswiper/slider_2_small.jpg',
    '/assets/powerswiper/slider_1_small.jpg',
    '/assets/powerswiper/slider_3_small.jpg',
    '/assets/powerswiper/slider_4_small.jpg'
  ];

  public currentSlideIndex = 0;
  public slideDuration = 3000;

  public transitingIndex: number = null;
  @ViewChild('slider', {static: true}) slider: ElementRef;
  @ViewChild('sliderPointer', {static: true}) pointer: ElementRef;
  public timerHandle = 0;
  @Input() isMobile: boolean = false;
  public isPhone() {
    return this.isMobile && window.innerHeight < 780;
  }
  constructor(public locomotiveScroll: LocomotiveScrollService) { }


  ngOnInit() {
      //this.timerHandle = setInterval(_ => this.nextSlide(), this.slideDuration)
  }


  public triggerNextSlide() {
    clearInterval(this.timerHandle);
    this.nextSlide();
  //  this.timerHandle = setInterval(_ => this.nextSlide(), this.slideDuration)
  }

  public  nextSlide() {
    this.transitingIndex = this.currentSlideIndex;
    this.currentSlideIndex++;
    if(this.currentSlideIndex >= this.images.length) {
      this.currentSlideIndex = 0;
    }
    //setTimeout(_ => this.transitingIndex = null, 1000)
  }

  public handleMouseover(event) {
    const rect = this.slider.nativeElement.getBoundingClientRect();
    const dx = event.clientX  - rect.x;
    const dy = event.clientY - rect.height * 0.8;
    this.pointer.nativeElement.style.transform = `translate3d(${dx}px,${dy}px, 0)`;
    this.pointer.nativeElement.style.left = 0;
  }

  public handleMouseleave(event) {
    this.pointer.nativeElement.style.transform = ``;
    this.pointer.nativeElement.style.left = '40%';
  }

}
