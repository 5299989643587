import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {LocomotiveScrollService} from "../../service/locomotive-scroll.service";
import {PortfolioImage} from "../../data/portoflio-model";

@Component({
  selector: ' app-list-swiper',
  templateUrl: './list-swiper.component.html',
  styleUrls: ['./list-swiper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListSwiperComponent implements OnInit, AfterViewInit {
  @Input() public images: PortfolioImage[];
  @Input() public swipeDelay: number;
  @Output() public imageSize = new EventEmitter<number>();

  public currentSlideIndex = 0;
  public slideDuration = 5000;


  public transitingIndex: number = null;
  public timerHandle = 0;

  constructor(public locomotiveScroll: LocomotiveScrollService,
              public renderer: Renderer2,
              public host: ViewContainerRef) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.timerHandle = setInterval(_ => this.nextSlide(), this.slideDuration);
    }, this.swipeDelay)
  }

  ngOnInit() {
  }

  public triggerNextSlide() {``
    clearInterval(this.timerHandle);
    this.nextSlide();
    this.timerHandle = setInterval(_ => this.nextSlide(), this.slideDuration)
  }

  public  nextSlide() {
    if(!this.images) {
      return;
    }
    this.transitingIndex = this.currentSlideIndex;
    this.currentSlideIndex++;
    if(this.currentSlideIndex >= this.images.length) {
      this.currentSlideIndex = 0;
    }
    //setTimeout(_ => this.transitingIndex = null, 1000)
  }
}
