import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PortfolioBaseComponent} from "../portfolio-base.component";
import {LocomotiveScrollService} from "../../../service/locomotive-scroll.service";
import {PortfolioImage, PortfolioType} from "../../../data/portoflio-model";

@Component({
  selector: 'app-portfolio-mitte',
  templateUrl: './portfolio-mitte.component.html',
  styleUrls: ['./portfolio-mitte.component.scss'],
  providers: [{ provide: PortfolioBaseComponent, useExisting: PortfolioMitteComponent }]
})
export class PortfolioMitteComponent extends PortfolioBaseComponent implements OnInit {
  public portfolioType = PortfolioType.mitte;
  public firstImages: PortfolioImage[] = [
    new PortfolioImage('./assets/new/mitte/erde/vertical/1.jpg'),
    new PortfolioImage('./assets/new/mitte/erde/vertical/2.jpg'),
  ];

  public secondImages: PortfolioImage[] = [
    new PortfolioImage('./assets/new/mitte/erde/horizontal/1.jpg'),
    new PortfolioImage('./assets/new/mitte/erde/horizontal/2.jpg'),
  ];

  constructor(elRef: ElementRef, public locomotiveScroll: LocomotiveScrollService) {
    super(elRef);
  }

  ngOnInit(): void {
  }
}
