import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-about-component',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  private mainSwiper: any;

  constructor() { }

  ngOnInit() {
  }


  onMainSwiper(swiper) {
    this.mainSwiper = swiper;
  }

}
