import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import {HeaderOrder, LocomotiveScrollService} from "../../service/locomotive-scroll.service";


@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: [
    `header.component.scss`
  ],
  /*  animations: [
      trigger('toggle', [
        state(
        ),
        state(
        ),
        transition('* => *', animate('200ms ease-in'))
      ])
    ]*/
})
export class HeaderComponent implements AfterViewInit {
  @ViewChild('header', { static: false }) header: ElementRef;
  @Input() isMobile: boolean;
  public order = HeaderOrder;
  public currentOrder = HeaderOrder.About;
  constructor(private elRef: ElementRef,
              public renderer: Renderer2,
              public container: ViewContainerRef,
              public locomotiveScroll: LocomotiveScrollService) {
  }

  public get isHorizontal(): boolean {
    return this.isMobile && window.innerWidth < window.innerHeight;
  }

  ngAfterViewInit() {
    let previous: { y: number, x: number } = null;
    let dropped =  false
    this.locomotiveScroll.headerOrderRx.subscribe(x => {
      this.currentOrder = x;
    });
    this.locomotiveScroll.on('scroll', evt => {
      if(!previous) {
        previous = Object.assign({}, evt.scroll);
      }

      if(!evt.delta) {
        evt.delta = {
          y: (evt.scroll.y - previous.y),
          x: (evt.scroll.x - previous.x)
        }
      }
      const baseOffset = (this.isHorizontal)
              ?  this.container.element.nativeElement.offsetTop
              : this.container.element.nativeElement.offsetLeft;
      const delta = (this.isHorizontal)? evt.scroll.y : evt.delta.x;
      if(delta > baseOffset) {
        dropped = true;
        //this.header.nativeElement.removeAttribute('data-scroll-section');
        this.renderer.removeAttribute(this.header.nativeElement, "data-scroll-section");
        this.renderer.removeAttribute(this.header.nativeElement, "style");
        this.renderer.addClass(this.header.nativeElement, 'header-wrapper-pinned');
        this.elRef.nativeElement.style.transform = null;
      } else if(dropped && !this.isHorizontal || dropped && this.isHorizontal && (evt.scroll.y < baseOffset + 60)) {
        dropped = false;
        //this.header.nativeElement.addAttribute('data-scroll-section');
        this.renderer.setAttribute(this.header.nativeElement, "data-scroll-section", "");
        this.renderer.removeClass(this.header.nativeElement, 'header-wrapper-pinned');
      }
      previous = Object.assign({}, evt.scroll);
    });
  }

  goTo(id) {
    this.locomotiveScroll.scrollTo(`#${id}`, { offset: -120 });
  }
}
