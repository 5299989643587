import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild
} from '@angular/core';
import {LocomotiveScrollService} from "../../service/locomotive-scroll.service";

@Component({
  selector: 'app-railtrack',
  templateUrl: './railtrack.component.html',
  styleUrls: ['./railtrack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RailtrackComponent implements AfterViewInit {
  @Input() public text: string = ''
  constructor(
    public locomotiveScroll: LocomotiveScrollService,
    public renderer: Renderer2) { }


  ngAfterViewInit() {

  }

}
