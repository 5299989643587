import {Injectable, OnDestroy, OnInit} from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ImageViewService implements OnInit, OnDestroy {
  public lastImageRx = new Subject<string>();
  public closeImageRx = new Subject<boolean>();

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  public openImage(src: string) {
    this.lastImageRx.next(src);
  }

  public closeImage() {
    this.closeImageRx.next(true);
  }
}
