import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LocomotiveScrollService} from "../../service/locomotive-scroll.service";
import {take, takeUntil, filter} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-videopreview',
  templateUrl: './videopreview.component.html',
  styleUrls: ['./videopreview.component.scss']
})
export class VideopreviewComponent implements AfterViewInit, OnDestroy {
  public CALL_EVENT_NAME = 'VideoComeout';
  public destroy = new Subject<boolean>();
  @ViewChild('player', { static: true }) player: ElementRef;
  constructor(public locomotiveScroll: LocomotiveScrollService) { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.player.nativeElement.pause();
    this.locomotiveScroll.callEventRx
      .pipe(takeUntil(this.destroy), filter(x => x === this.CALL_EVENT_NAME))
      .subscribe(x => {
        this.player.nativeElement.muted = true;
        this.player.nativeElement.play().then(r => {});
      });
  }

  ngOnDestroy(): void {
    if(this.destroy) {
      this.destroy.next(true);
    }
  }

}
