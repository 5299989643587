import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }

  public goToInstagram()
  {
    return window.location.href = 'https://instagram.com/qsk_company';
  }
}
