import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {LocomotiveScrollService} from "../../service/locomotive-scroll.service";
import {skipWhile} from "rxjs/operators";
import {PortfolioType} from "../../data/portoflio-model";

@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss'],
})
export class BrandListComponent implements OnInit {
  public  inView: boolean = false;
  @ViewChild('backgrounds', {static: false}) backgrounds: ElementRef;
  @ViewChild('brandList', {static: false}) brandList: ElementRef;
  public portfolioType = PortfolioType;

  constructor(public locomotiveScroll: LocomotiveScrollService) { }

  ngOnInit() {
    this.locomotiveScroll.callEventRx
      .pipe(skipWhile(x => !x || x !== 'brand-section'))
      .subscribe(event => {
        this.inView=true;
      });
  }

  mouseEnterOnLink(event) {
    const dataId = event.target.children[0].getAttribute('data-id');
    this.brandList.nativeElement.classList.add('is-active');
    for(let last of this.backgrounds.nativeElement.children) {
      if(last.getAttribute('data-id') === dataId) {
        last.classList.add('is-active');
      } else if(last.getAttribute('data-id')) {
        last.classList.remove('is-active');
      }
    }
  }
  @Output() public brandListSelected = new EventEmitter<PortfolioType>();
  public onBrandListSelected(definition: PortfolioType) {
    this.brandListSelected.emit(definition);
  }
  mouseLeaveOnLink(event) {
  }

  mouseLeaveOnContainer() {
    for(let last of this.backgrounds.nativeElement.children) {
      last.classList.remove('is-active');
    }
    this.brandList.nativeElement.classList.remove('is-active');
  }


  public  scrollTo(target: string) {
    this.locomotiveScroll.scrollTo(target);
  }
}
