import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PortfolioBaseComponent} from "../portfolio-base.component";
import {LocomotiveScrollService} from "../../../service/locomotive-scroll.service";
import {PortfolioImage, PortfolioType} from "../../../data/portoflio-model";

@Component({
  selector: 'app-portfolio-cmt',
  templateUrl: './portfolio-cmt.component.html',
  styleUrls: ['./portfolio-cmt.component.scss'],
  providers: [{ provide: PortfolioBaseComponent, useExisting: PortfolioCmtComponent }]
})
export class PortfolioCmtComponent extends PortfolioBaseComponent implements OnInit {
  public portfolioType = PortfolioType.cmt;

  constructor(elRef: ElementRef, public locomotiveScroll: LocomotiveScrollService) {
    super(elRef);
  }

  ngOnInit(): void {
  }

  public firstImages: PortfolioImage[] = [
    new PortfolioImage('./assets/new/cmt/1.jpg'),
    new PortfolioImage('./assets/new/cmt/2.jpg'),
    new PortfolioImage('./assets/new/cmt/3.jpg'),
    new PortfolioImage('./assets/new/cmt/4.jpg'),
    new PortfolioImage('./assets/new/cmt/5.jpg'),
    new PortfolioImage('./assets/new/cmt/6.jpg'),
    new PortfolioImage('./assets/new/cmt/7.jpg'),
  ];


}
