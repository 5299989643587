import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ElementRef, EventEmitter,
  Input,
  OnInit,
  Output, QueryList, Renderer2, ViewChild, ViewChildren, ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {LocomotiveScrollService} from "../../service/locomotive-scroll.service";
import {PortfolioImage} from "../../data/portoflio-model";
import {PaginationOptions} from "swiper/types";
import SwiperCore, {Autoplay, Pagination, Swiper} from "swiper";
import {SwiperComponent} from "swiper_angular";

SwiperCore.use([
  Pagination,
  Autoplay,
]);

@Component({
  selector: ' app-list-swiper-new',
  templateUrl: './list-swiper-new.component.html',
  styleUrls: ['./list-swiper-new.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListSwiperNewComponent {
  @Input() public images: PortfolioImage[];
  @Input() public swipeDelay: number;
  @Input() public colorFractionClass: '' | 'white' | 'black' = '';
  @ViewChildren("slideImage") slides: QueryList<ElementRef>;
  @Output() public imageSize = new EventEmitter<number>();

  public currentSlideIndex = 0;
  public slideDuration = 5000;
  @ViewChild('swiper', { static: true }) swiper: SwiperComponent;
  public paginationOptions: PaginationOptions = {
    type: "fraction",
    renderFraction: (currentClass, totalClass)  => {
      return '<span class="' + this.colorFractionClass + '"><span class="' + currentClass + '"></span>' + ' <span> — </span> ' + '<span class="' + totalClass + '"></span></span>';
    }
  }

  constructor(public locomotiveScroll: LocomotiveScrollService,
              public renderer: Renderer2,
              public cdr: ChangeDetectorRef,
              public host: ViewContainerRef) {
  }

  public checkSwiper(swiper): void {
    setTimeout(() => {
      this.slides.forEach(img => {
        img.nativeElement.parentElement.parentElement.style.width = String(img.nativeElement.clientWidth) + 'px';
        img.nativeElement.parentElement.parentElement.style.height = String(img.nativeElement.clientHeight) + 'px';
      })
    }, 0)
  }

  public nextSlide() {
    this.swiper.swiperRef.slideNext(1000);
  }
  ngOnInit() {
  }

}
