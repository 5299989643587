import {Component, ElementRef, OnInit, Output, ViewChild, ViewChildren, ViewContainerRef} from '@angular/core';
import anime from "animejs/lib/anime.es.js";
import { Subject} from "rxjs";
import {LocomotiveScrollService} from "../../service/locomotive-scroll.service";
import {PortfolioImage, toPreloadImages} from "../../data/portoflio-model";

@Component({
  selector: 'preloader-component',
  templateUrl: 'preloader.component.html',
  styleUrls: ['preloader.component.scss']
})
export class PreloaderComponent implements OnInit {
  @ViewChild('svgContainer', { static: true}) public svgContainer: ElementRef;
  @ViewChild('container', { static: true}) public container: ElementRef;
  @Output() completed = new Subject<boolean>()
  constructor(public locomotiveScrollService: LocomotiveScrollService) {
  }

  ngOnInit() {
    const timeDuration = 1800;
    this.preload(toPreloadImages);
    const opacityAnimation = anime({
      targets: '.red',
      opacity: 1,
      easing: 'linear',
      duration: timeDuration,
      complete: () => {
        this.locomotiveScrollService.dispatchScroll();
        anime({
          targets: '.preloader-container',
          translateY: '-100vh',
          easing: 'easeInQuad',
          duration: timeDuration / 3,
          complete: _ => {
            setTimeout(_ => {
              this.completed.next(true);
              this.container.nativeElement.style.display = 'none';
            }, 0);
          }
        });
      }
    });
  }

  public loaded: HTMLImageElement[] = [];
  public preload(images: PortfolioImage[]) {
      for(let image of images) {
        const img = new Image();
        img.src = image.path;
        this.loaded.push(img);
      }
  }
}


