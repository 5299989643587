import {AfterViewInit, Directive, ElementRef, HostListener, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import anime from "animejs/lib/anime.es.js";
import {ImageViewService} from "../service/image-view.service";

@Directive({
  selector: '[appImageView]'
})
export class ImageViewDirective implements AfterViewInit {
  @Input() public imageSrc: string;
  public _targetAnime;

  constructor(
    public el: ElementRef,
    public imageViewService: ImageViewService
  ) {
  }

  @HostListener('mouseenter')
  @HostListener('auxclick')
  onMouseEnter() {
    this._targetAnime = anime({
      targets: this.el.nativeElement,
      scale: 1.05,
      easing: 'linear',
      duration: 900,
      complete: ani => {
        if(ani.reversed) {
          return;
        }
        this.imageViewService.openImage(this.imageSrc);
        anime({
          targets: this.el.nativeElement,
          scale: 1,
          easing: 'linear',
          duration: 300,
        });
      }
    });
  }

  @HostListener('mouseleave') onMouseLeave() {
    this._targetAnime.reverse();
  }

  ngAfterViewInit(): void {
  }
}
