import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';
import {LocomotiveScrollService} from "../service/locomotive-scroll.service";
import {combineLatest, of} from "rxjs";
import {delay, skipWhile} from "rxjs/operators";
import anime from "animejs/lib/anime.es";
import { AnimeManagerService } from "../service/anime-manager.service";

@Directive({
  selector: '[appComeoutAnimation]'
})
export class ComeoutAnimationDirective implements AfterViewInit {
  @Input() public comeoutDuration: number = 1000;
  @Input() public comeoutOffset: number = 0;
  public comeoutCallname: string = '';

  constructor(
    public el: ElementRef,
    public locomotiveScroll: LocomotiveScrollService,
    public animeService: AnimeManagerService) {
  }

  ngAfterViewInit(): void {
    this.el.nativeElement.style.overflow = 'hidden';
    this.comeoutCallname = this.el.nativeElement.getAttribute("data-scroll-call");

    for(let child of this.el.nativeElement.children) {
      child.style.position = 'relative';
      child.style.top = '100vh';
    }

    combineLatest([
      this.locomotiveScroll.callEventRx.pipe(skipWhile(x => !x || x !== this.comeoutCallname)),
      this.locomotiveScroll.preloaderFinished.pipe(skipWhile(x => !x)),
      of(true).pipe(delay(1450))
    ]).subscribe(arg => {
      for(let child of this.el.nativeElement.children) {
        setTimeout(_ => {
          const opacityAnimation = this.animeService.startAnime({
            targets: child,
            top: 0,
            easing: 'easeOutQuad',
            duration: this.comeoutDuration,
            complete: () => {
            }
          });
        }, this.comeoutOffset);
      }
    });
  }

}
