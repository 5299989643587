import {Injectable, OnDestroy, OnInit} from "@angular/core";
import LocomotiveScroll from 'locomotive-scroll';
import {MobileService} from "./mobile.service";
import {takeUntil} from "rxjs/operators";
import {BehaviorSubject, Subject} from "rxjs";
import {ScrollEvent} from "../data/scroll";

export class CallbackDefinition
{
  constructor(
    public event: string,
    public callback: any
  ) {
  }
}

export enum HeaderOrder {
  About = 0,
  Portfolio = 1,
  Contacts = 2
}

@Injectable({providedIn: 'root'})
export class LocomotiveScrollService implements OnInit, OnDestroy {
  private _scroll: LocomotiveScroll;
  private _isMobile: boolean = false;
  private _destroy = new Subject<boolean>();
  private _callbacks: CallbackDefinition[] = [];
  public preloaderFinished = new Subject<boolean>();
  public callEventRx = new BehaviorSubject<string>('');
  public scrollEventRx = new Subject<ScrollEvent>();
  private _el: Element;
  constructor(public mobileService: MobileService) {
  }

  public ngOnInit() {
    this._el = document.querySelector('[data-scroll-container]');
    this.init();

    this.mobileService.isMobileRx
      .pipe(takeUntil(this._destroy))
      .subscribe(x => {
        if(this._isMobile == x) {
          return;
        }
        this._isMobile = x;
        //this.reinit();
      });
  }

  ngOnDestroy(): void {
    this.destroy();
    this._destroy.next(true);
    this._destroy.complete();
  }

  public update() {
    this._scroll.update();
  }

  public dispatchScroll() {
    this._scroll.scroll.checkScroll();
  }
  public reinit() {
    setTimeout(_ => {
      this.destroy();
      this.init();
      for(let callback of this._callbacks) {
        this._scroll.on(callback.event, callback.callback);
      }
    }, 0);
  }

  public scrollTo(value: number | string, opts?: any) {
    this._scroll.scrollTo(value, opts);
  }

  public on(evt: string, callback: any) {
    this._callbacks.push(new CallbackDefinition(evt, callback))
    this._scroll.on(evt, callback)
  }

  public get el(): Element {
    return this._el;
  }
/*
*   el: document,
        name: "scroll",
        offset: [0, 0],
        repeat: !1,
        smooth: !1,
        initPosition: {
            x: 0,
            y: 0
        },
        direction: "vertical",
        gestureDirection: "vertical",
        reloadOnContextChange: !1,
        lerp: .1,
        class: "is-inview",
        scrollbarContainer: !1,
        scrollbarClass: "c-scrollbar",
        scrollingClass: "has-scroll-scrolling",
        draggingClass: "has-scroll-dragging",
        smoothClass: "has-scroll-smooth",
        initClass: "has-scroll-init",
        getSpeed: !1,
        getDirection: !1,
        scrollFromAnywhere: !1,
        multiplier: 1,
        firefoxMultiplier: 50,
        touchMultiplier: 2,
        resetNativeScroll: !0,
        tablet: {
            smooth: !1,
            direction: "vertical",
            gestureDirection: "vertical",
            breakpoint: 1024
        },
        smartphone: {
            smooth: !1,
            direction: "vertical",
            gestureDirection: "vertical"
        }
    }
* */
  public init() {
    this._scroll = new LocomotiveScroll({
      el: this._el,
      smooth: true,
      direction: 'horizontal',
      offset: [0, 0],
      getDirection: true,
      getSpeed: true,
      gestureDirection: 'vertical',
      reloadOnContextChange: !1,
      lerp: 0.1,
      class: "is-inview",
      scrollbarContainer: false,
      scrollFromAnywhere: false,
      multiplier: 1,
      firefoxMultiplier: 50,
      touchMultiplier: 2,
      resetNativeScroll: true,
      smartphone: {
        smooth: false,
        direction: 'vertical',
        gestureDirection: 'vertical'
      },
      tablet: {
        breakpoint: 1020,
        smooth: true,
        direction: window.innerWidth > window.innerHeight ? 'horizontal' : 'vertical',
        gestureDirection: window.innerWidth > window.innerHeight ? 'horizontal' : 'vertical'
      },
    });
    this._scroll.on('call', (args) => this.callEventRx.next(args));
    this._scroll.on('scroll', (args) => this.scrollEventRx.next(args));
  }

  public destroy() {
    if(!this._scroll)
      return;
    this._scroll.off('call');
    this._scroll.off('scroll');
    this._scroll.offset = [0, 0]
    this._scroll.destroy()
  }
  public headerOrder = HeaderOrder.About;
  public headerOrderRx = new Subject<HeaderOrder>()
  public setLastHeader(order: HeaderOrder) {
    this.headerOrder = order;
    this.headerOrderRx.next(order);
  }
}
