
export enum PortfolioType {
  mitte = 'mitte',
  mond = 'mond',
  cvet321 = 'cvet321',
  cvet322 = 'cvet322',
  moss = 'moss',
  celine = 'celine',
  arteco = 'arteco',
  cmt = 'cmt'
}


export class PortfolioImage {
  constructor(public path: string) {
  }
}


export const toPreloadImages: PortfolioImage[] = [
  new PortfolioImage('./assets/new/arteco/vertical/1.jpg'),
  new PortfolioImage('./assets/new/arteco/vertical/2.jpg'),
  new PortfolioImage('./assets/new/arteco/vertical/3.jpg'),
  new PortfolioImage('./assets/new/arteco/horizontal/1.jpg'),
  new PortfolioImage('./assets/new/arteco/horizontal/2.jpg'),
  new PortfolioImage('./assets/new/arteco/horizontal/3.jpg'),
  new PortfolioImage('./assets/new/arteco/horizontal/4.jpg'),
  new PortfolioImage('./assets/new/cmt/1.jpg'),
  new PortfolioImage('./assets/new/cmt/2.jpg'),
  new PortfolioImage('./assets/new/cmt/3.jpg'),
  new PortfolioImage('./assets/new/cmt/4.jpg'),
  new PortfolioImage('./assets/new/cmt/5.jpg'),
  new PortfolioImage('./assets/new/cmt/6.jpg'),
  new PortfolioImage('./assets/new/cmt/7.jpg'),
  new PortfolioImage('./assets/new/cvet32/1/1.jpg'),
  new PortfolioImage('./assets/new/cvet32/1/2.jpg'),
  new PortfolioImage('./assets/new/cvet32/1/3.jpg'),
  new PortfolioImage('./assets/new/cvet32/1/4.jpg'),
  new PortfolioImage('./assets/new/cvet32/1/5.jpg'),
  new PortfolioImage('./assets/new/cvet32/1/6.jpg'),
  new PortfolioImage('./assets/new/cvet32/2/vertical/1.jpg'),
  new PortfolioImage('./assets/new/cvet32/2/vertical/2.jpg'),
  new PortfolioImage('./assets/new/cvet32/2/horizontal/1.jpg'),
  new PortfolioImage('./assets/new/cvet32/2/horizontal/2.jpg'),
  new PortfolioImage('./assets/new/mitte/erde/vertical/1.jpg'),
  new PortfolioImage('./assets/new/mitte/erde/vertical/2.jpg'),
  new PortfolioImage('./assets/new/mitte/erde/horizontal/1.jpg'),
  new PortfolioImage('./assets/new/mitte/erde/horizontal/2.jpg'),
  new PortfolioImage('./assets/new/mitte/mond/vertical/1.jpg'),
  new PortfolioImage('./assets/new/mitte/mond/vertical/2.jpg'),
  new PortfolioImage('./assets/new/mitte/mond/horizontal/1.jpg'),
  new PortfolioImage('./assets/new/mitte/mond/horizontal/2.jpg'),
];
