import {AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {HeaderOrder, LocomotiveScrollService} from "../service/locomotive-scroll.service";
import { AnimeManagerService } from "../service/anime-manager.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Directive({
  selector: '[appHighlightWhenInview]'
})
export class HighlightWhenInviewDirective implements AfterViewInit, OnDestroy, OnChanges {
  @Input() public targetId: string;
  @Input() public order: HeaderOrder;
  public unsubscribe = new Subject<void>();
  constructor(
    public el: ElementRef,
    public locomotiveScroll: LocomotiveScrollService,
    public animeService: AnimeManagerService) {
  }

  highlightLastHeader() {
    if(this.locomotiveScroll.headerOrder == this.order) {
      this.el.nativeElement.style.borderBottom = '1px solid black';
    } else {
      this.el.nativeElement.style.borderBottom = '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.highlightLastHeader();
  }


  ngOnDestroy(): void {
    if(this.unsubscribe) {
      this.unsubscribe.next();
      this.unsubscribe.complete();
    }
  }

  ngAfterViewInit(): void {
  }
}
