import {
  AfterContentInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {PreloaderComponent} from "./components/preloader/preloader.component";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {MobileService} from "./service/mobile.service";
import {ActivatedRoute} from "@angular/router";
import {HeaderOrder, LocomotiveScrollService} from "./service/locomotive-scroll.service";
import {PortfolioBaseComponent} from "./components/portfolio/portfolio-base.component";
import {BrandListComponent} from "./components/brand-list/brand-list.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  title = 'QSK company';
  @ViewChild(PreloaderComponent, {static: true}) preloader: PreloaderComponent;
  @ViewChildren(PortfolioBaseComponent, { read: PortfolioBaseComponent }) portfolios: QueryList<PortfolioBaseComponent>;
  @ViewChild(BrandListComponent, { static: true }) brandList: BrandListComponent;
  isMobile: boolean = false;
  finishPreloader = false;
  destroy = new Subject<boolean>();

  constructor(
    public mobileService: MobileService,
    public data: ActivatedRoute,
    public locomotiveScrollService: LocomotiveScrollService) {
  }

  public get isHorizontal(): boolean {
    return this.isMobile && window.innerWidth < window.innerHeight;
  }

  ngAfterContentInit(): void {
    this.preloader.completed.pipe(takeUntil(this.destroy)).subscribe(_ => {
      this.finishPreloader = true;
      this.locomotiveScrollService.preloaderFinished.next(true);
      this.locomotiveScrollService.reinit();
      window.dispatchEvent(new Event('resize'));
    });

    this.mobileService.isMobileRx
      .pipe(takeUntil(this.destroy))
      .subscribe(isMobile => {
         if(this.isMobile == isMobile) {
          return;
        }
        this.isMobile = isMobile;
        this.locomotiveScrollService.reinit();
      })
  }

  ngAfterViewInit(): void {
    this.locomotiveScrollService.scrollEventRx.pipe(takeUntil(this.destroy)).subscribe(x => {
      if(!x.currentElements) {
        return;
      }
      const elements = Object.values(x.currentElements).filter(x => !!x.target);
      const elementsInView = elements.map(x => x.target.id);
      if(elementsInView.includes('about')) {
        this.locomotiveScrollService.setLastHeader(HeaderOrder.About);
      }
      if(elementsInView.includes('portfolio')) {
        this.locomotiveScrollService.setLastHeader(HeaderOrder.Portfolio);
      }
      if(elementsInView.includes('contacts')) {
        this.locomotiveScrollService.setLastHeader(HeaderOrder.Contacts);
      }
    });
    if(this.brandList) {
      this.brandList.brandListSelected.pipe(takeUntil(this.destroy)).subscribe(definition => {
        const portfolio = this.portfolios.find(x => x.portfolioType == definition);
        this.locomotiveScrollService.scrollTo(
          this.isHorizontal ? portfolio.elRef.nativeElement.offsetTop : portfolio.elRef.nativeElement.offsetLeft);
      });
    }
  }


  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
    this.locomotiveScrollService.ngOnDestroy();
  }

  ngOnInit(): void {
    this.locomotiveScrollService.ngOnInit();
  }

  @HostListener('window:orientationchange', ['$event'])
  public orientationChangeEvent($event) {
    this.locomotiveScrollService.reinit();
    this.locomotiveScrollService.dispatchScroll();
    this.locomotiveScrollService.update();

  }
}
