import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit {
  @Input() src: string;
  @Input() id: string;
  @Input() height: string;
  @Input() width: string;
  @ViewChild('image', { static: false }) imageContainer: ElementRef;
  @ViewChild('root', { static: false }) rootContainer: ElementRef;
  @ViewChild('section', { static: false }) section: ElementRef;
  @ViewChild('fullscreen', { static: false }) fullscreen: ElementRef;

  public showFullscreen = false;
  constructor(public renderer : Renderer2) { }

  ngOnInit() {

  }
}
