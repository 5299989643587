import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PortfolioBaseComponent} from "../portfolio-base.component";
import {LocomotiveScrollService} from "../../../service/locomotive-scroll.service";
import {PortfolioImage, PortfolioType} from "../../../data/portoflio-model";

@Component({
  selector: 'app-portfolio-mitte-mond',
  templateUrl: './portfolio-mitte-mond.component.html',
  styleUrls: ['./portfolio-mitte-mond.component.scss'],
  providers: [{ provide: PortfolioBaseComponent, useExisting: PortfolioMitteMondComponent }]
})
export class PortfolioMitteMondComponent extends PortfolioBaseComponent implements OnInit {
  public portfolioType = PortfolioType.mitte;

  constructor(elRef: ElementRef, public locomotiveScroll: LocomotiveScrollService) {
    super(elRef);
  }

  ngOnInit(): void {
  }


  public firstImages: PortfolioImage[] = [
    new PortfolioImage('./assets/new/mitte/mond/vertical/1.jpg'),
    new PortfolioImage('./assets/new/mitte/mond/vertical/2.jpg'),
  ];

  public secondImages: PortfolioImage[] = [
    new PortfolioImage('./assets/new/mitte/mond/horizontal/1.jpg'),
    new PortfolioImage('./assets/new/mitte/mond/horizontal/2.jpg'),
  ]

}
