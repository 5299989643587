import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input, OnInit, Output,
  Renderer2,
} from '@angular/core';
import {LocomotiveScrollService} from "../service/locomotive-scroll.service";
import {skipWhile, takeUntil, takeWhile} from "rxjs/operators";
import anime from "animejs/lib/anime.es.js";
import {AnimeManagerService} from "../service/anime-manager.service";

@Directive({
  selector: '[appZoomout]'
})
export class ZoomoutDirective implements OnInit, AfterViewInit {
  @Input() rootRef: HTMLElement;
  @Input() zoomoutId: string;
  @Input() sourceScale: number = 1.8;
  private _targetScale = 1.3;
  @Input() public set targetScale(val: number) {
    this._targetScale = val;
    this.toggleZoomout();
  }
  public get targetScale() {
    return this._targetScale;
  }

  @Input() zoomoutDuration: number = 3700;
  @Output() public zoomoutFinished = false;
  public _isZoomoutActive: boolean = false;
  public get isZoomoutActive(): boolean {
    return this._isZoomoutActive;
  }
  @Input() public set isZoomoutActive(val: boolean) {
    const changed = this._isZoomoutActive != val;
    this._isZoomoutActive = val;
    changed && this.toggleZoomout()
  }
  constructor(
    public el: ElementRef,
    private renderer: Renderer2,
    public animeManager: AnimeManagerService,
    public locomotiveScroll: LocomotiveScrollService
  ) {
  }

  ngOnInit() {
    if(this.isZoomoutActive) {
      this.renderer.setStyle(this.el.nativeElement, 'transform', 'scale(' + this.sourceScale + ')');
      this.renderer.addClass(this.el.nativeElement, 'has-zoomout');
    }
  }

  ngAfterViewInit(): void {
    this.toggleZoomout();
  }

  public toggleZoomout() {
    if(!this.isZoomoutActive && !this.zoomoutFinished) {
      return;
    }
    this.locomotiveScroll.dispatchScroll();
    this.locomotiveScroll.callEventRx
      .pipe(skipWhile(x => !x || x !== this.zoomoutId), takeWhile(() => !this.zoomoutFinished))
      .subscribe(_ => {
        this.animeManager.startAnime({
          targets: this.el.nativeElement,
          easing: 'linear',
          scale: this.targetScale,
          duration: this.zoomoutDuration,
          complete: () => {
            this.zoomoutFinished = true;
            this.renderer.addClass(this.el.nativeElement, 'zoomout-finished');
          }
        });
      });
  }
}
