import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit} from '@angular/core';
import {PortfolioImage, PortfolioType} from "../../data/portoflio-model";
@Component({
  selector: 'app-portfolio-base',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfolioBaseComponent implements OnInit {
  public portfolioType: PortfolioType;
  @Input() isMobile: boolean = false;

  constructor(public elRef: ElementRef) {
  }

  ngOnInit() {
  }
}
